@import "./../../colors";

.fixed-nav-bar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 50px;
    background-color: #00a087;
}

.navbar-light .navbar-nav .nav-link.active:hover {
    color: $primary !important;
}
