@import "colors";

$theme-colors: (
  "primary": $primary,
  "warning": $warning,
  "danger": $danger
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

h1 {
  color: #533f03;
}

.content-wrapper {
  background-color: #ffffff;
}

.table-header {
  background-color: $primary;
}

.table-header {
  background-color: $primary !important;
  color: white !important;
}

.toast-success-container {
  background: $primary !important;
  border: 1px solid $primary !important;
  box-shadow: 0 1px 5px rgba(248, 175, 175, 0.1) !important;
}

.toast-success-container-after::after{
  background-color: $primary;
}

.toast-info-container {
  color: rgba(0,0,0,.9) !important;;
  background: $warning !important;
  border: 1px solid $warning !important;
  box-shadow: 0 1px 5px rgba(248, 175, 175, 0.1) !important;
}

.toast-info-container-after::after{
  color: rgba(0,0,0,.9) !important;
  background-color: $warning !important;;
}

.brand-link {

  font-size: 1.25rem;
  line-height: 1.5;
  padding: 0.8125rem 0.5rem;
  transition: width 0.3s ease-in-out;
}

.brand-link:link { text-decoration: none; color: $primary; }
.brand-link:visited { text-decoration: none; color: $primary; }
.brand-link:hover { text-decoration: none; color: $primary; }
.brand-link:active { text-decoration: none; color: $primary; }
.user-panel { border-bottom: 1px solid #dddddd; }
.user-panel .image { color: #6c757d; }
.user-panel .info a:link { text-decoration: none; color: $primary; }
.user-panel .info:visited { text-decoration: none; color: $primary; }
.user-panel .info:hover { text-decoration: none; color: $primary; }
.user-panel .info:active { text-decoration: none; color: $primary; }

.nav-link:hover {
  color: $primary !important;
}

.nav-link.active:hover {
  color: white !important;
}


.menu-panel { border-bottom: 1px solid #dddddd; }

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #01773d;
  border-color: #008041;
  box-shadow: 0 0 0 0 rgba(246, 246, 246, 0.5);
}

.btn-primary:active {
  color: #fff;
  background-color: #01773d;
  border-color: #008041;
  box-shadow: 0 0 0 0 rgba(246, 246, 246, 0.5);
}
.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #01773d;
  border-color: #008041;
  box-shadow: 0 0 0 0 rgba(246, 246, 246, 0.5);
}

input[type="checkbox"] {
  accent-color: $primary
}

.react-datepicker__time-list-item--disabled {
  display: none;
}

.brand-text {
   color: $primary;
 }
