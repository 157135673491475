@import "./../../colors";

.confirm-box-container {
    display: none;
}

.confirm-box-content {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #00000061;
    display: flex;
    justify-content: center;
    align-items: center;
}

.confirm-box {
    position: relative;
    max-width: 400px;
    background-color: #ffffff;
    border-radius: 5px;
    border-radius: .25em .25em .4em .4em;
    box-shadow: 0 0 20px rgba(0, 0, 0, .2);
}

/* header */
.confirm-box-header {
    display: flex;
    padding: 40px 20px;
    justify-content: center;
    align-items: center;
}

.confirm-box-header img {
    margin-right: 20px;
}

.confirm-box-header p {
    margin: 0;
    font-size: 18px !important;
    color: #3e3e3e;
}

/* buttons */
.confirm-btn {
    width: 100%;
    display: flex;
}

.confirm-box .confirm-btn button {
    width: 100% !important;
    background-color: $primary;
    color: #fff;
    border: 0;
    padding: 20px;
    font-size: 18px;
    text-transform: uppercase;
    border-radius: 0 0 5px 0;
    cursor: pointer;
    transition: background-color .2s;
    outline: none !important;
}

.confirm-box button:hover {background-color: #626b7d;}

.confirm-box button:nth-child(1) {
    color: #333333;
    background-color: $warning;
    border-radius: 0;
    border-radius: 0 0 0 5px;
}

.confirm-box button:nth-child(1):hover {
    color: #333333;
    background-color: #626b7d;
}

/* close button */
.confirm-box span {
    font-size: 30px;
    position: absolute;
    right: 10px;
    top: 2px;
    cursor: pointer;
}

/* media query */
@media screen and (max-width: 378px) {
    .confirm-btn {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .confirm-box .confirm-btn button { border-radius: 0 0 5px 5px; }
    .confirm-box button:nth-child(1) { border-radius: 0; }
    .confirm-box-header img { display: none; }
}
